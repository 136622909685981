import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

let sequence = Promise.resolve();

applications.forEach((app) => {
  sequence = sequence.then(() => {
    return System.import(app.name).then(() => {
      registerApplication(app);
    });
  });
});

sequence.then(() => {
  layoutEngine.activate();
  start();
});
